$colourWorm: #dd9094;
$colourBee: #ceb138;
$colourCicada: #4caee5;
$colourGrub: #f2f0c7;
$colourMoth: #b2a157;
$colourXmas: #aec685;
$colourGlow: #6dc9c9;
$colourWeta: #a36b3c;

// TYPOGRAPHY
// ==========================================================================
// 1. make font slightly thicker
@mixin font-base {
  font-family: "DK-Lemon-Yellow-Sun", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-shadow: 0 0 0.05rem #000; // [1]
  letter-spacing: 0.07rem;
}

@mixin font-winkle {
  font-family: "Winkle", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

@mixin font-copy {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

// RESET
@mixin reset-button {
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
}

// SIZES
// ==========================================================================
$header-height: 7rem;
$header-height-mobile: 6rem;
$footer-height: 4rem;

$border-width: 0.2rem;

// Z-INDEX
// ==========================================================================
$z-index-dropdown: 100;
$z-index-header: 900;

// TRANSITIONS
@mixin transitions-base {
  transition: all 300ms;
  transition-timing-function: cubic-bezier(0.62, -0.2, 0.35, 0.5);
}

$small-mobile: 330px;
$short-mobile: 520px;
