aside.activities {
    @include transitions-base;
    position: absolute;
    width: 100vw;
    right: 0;
    top: 0;
    padding: 1.5rem;
    border: $border-width solid #000;
    border-left: 0;
    border-right: 0;
    background: #fff;
    text-align: center;
    pointer-events: none;
    opacity: 0;

    @include bp(s) {
        padding: 2.4rem;
        width: auto;
        min-width: 38.8rem;
        border: $border-width solid #000;
    }

    &.activities--is-open {
        top: calc(#{$header-height-mobile} - #{$border-width});
        opacity: 1;
        z-index: $z-index-dropdown;
        pointer-events: all;
        
        @include bp(s) {
        top: calc(#{$header-height} - #{$border-width});
        }
    }
    
    h3 {
        @include font-base;
        margin: 0;
        font-size: 1.8rem;
        line-height: 1;
    }

    ul {
        padding: 0;
        margin: 1.8rem 0 0 0;
    }

    li {
        list-style-type: none;
        line-height: 1;

        + li {
            margin-top: 1.5rem;
        }
    }

    a {
        color: #000;
        text-decoration: none;
        line-height: 1;

        &:hover,
        &:focus {
            border-bottom: $border-width solid black;
        }

        &:focus {
            outline: 0;
        }
    }
}