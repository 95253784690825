html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body {
  padding: 0;
  margin: 0;
  background: #fff;
}
body {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h1,
h2,
h3,
h4 {
  margin-top: 0;
}
a {
  color: #000;
}
.button,
a.button {
  background: #000;
  color: #fff;
  outline: 0;
  border: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  width: 100%;
  padding: 1.3rem 1rem;
  display: block;
  text-decoration: none;
  line-height: 1.4;

  @include mqh($short-mobile) {
    padding: 1rem;
    font-size: 1.6rem;
  }
}
