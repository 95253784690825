.bbh-clue {
  padding: 3rem;
  text-align: center;
  background-color: #f8f8fd;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .bbh-clue-inner {
    border: 0.2rem solid #000;
    background-color: #fff;
    border-radius: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.5rem;
    position: relative;

    .close-button {
      position: absolute;
      top: 2rem;
      left: 2rem;
    }
    .clue-copy {
      text-align: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: 3rem;

      @include mqh($short-mobile) {
        margin-bottom: 1.5rem;
      }
      // justify-content: center;

      .bug-img {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 50%;
        min-height: 5rem;
        max-height: 10rem;
        margin-bottom: 2rem;

        @include mqh($short-mobile) {
          margin-bottom: 1rem;
        }
      }
      h3 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;

        @include mqh($short-mobile) {
          font-size: 1.3rem;
        }
      }
      p {
        @include font-copy();
        font-size: 1.8rem;
        line-height: 1.33;
        margin: 0;

        @include mq($small-mobile) {
          font-size: 1.6rem;
        }
        @include mqh($short-mobile) {
          font-size: 1.4rem;
        }
      }
    }
    .product-code-wrap {
      flex-grow: 1;
      margin-bottom: 2rem;

      @include mqh($short-mobile) {
        margin-bottom: 1rem;
      }
    }
    .product-code {
      .scratchy-content {
        border: 0.2rem #000 solid;
        padding: 2rem 3rem;
        border-radius: 0.1rem;
        width: 100%;
        max-width: 24rem;

        @include mqh($short-mobile) {
          padding: 1rem 1.5rem;
        }

        .clue-code {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;

          span {
            font-size: 2.2rem;
            font-weight: bold;
          }
          .copy-button {
            background: #f8f8fd;
            border: 0.1rem #eee solid;
            color: #000;
            font-size: 1.4rem;
            padding: 0.5rem 2rem;
            flex-shrink: 1;
            width: auto;
            margin-left: 1rem;
            min-width: 9rem;
          }
        }
        .instruction {
          font-size: 1.4rem;
          line-height: 1.2;
        }
      }
    }
    .scan-link {
      width: 100%;
      max-width: 24rem;

      @include mqh($short-mobile) {
        font-size: 1.4rem;
      }
      a.button {
        width: 100%;
        display: block;
        margin-top: 0.5rem;
      }
    }
  }
}
